<template>
  <ClientOnly>
    <template #fallback>
      <Loading :class="chartHeight">{{ $t('cryptoMarket.chartLoading') }}</Loading>
    </template>
    <div
      ref="chartWrapperRef"
      class="relative"
      :class="chartHeight"
      :style="{
        marginLeft: hasMargins ? `-${MARGIN_X}px` : 0,
        marginRight: hasMargins ? `-${MARGIN_X}px` : 0
      }"
    >
      <LoadingOverlay v-bind="loading">
        {{ $t('cryptoMarket.chartLoading') }}
      </LoadingOverlay>
      <ErrorOverlay v-bind="error" :message="$t('cryptoMarket.chartError')" />
      <Chart v-if="isVisible" :options="chartOptions" class="h-full w-full" />
      <Teleport v-if="isTooltipVisible" :to="`#${id}`">
        <ChartTooltip
          v-if="tooltipData"
          v-bind="tooltip"
          :date="tooltipData.date"
          :dateFormat="range === DateTimeRange.ONE_DAY ? 'HH:mm' : undefined"
          :value="tooltipData.value"
          :secondary-value="tooltipData.secondaryValue"
        />
      </Teleport>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { Chart } from 'highcharts-vue'
import { TooltipProps } from './ChartTooltip.vue'
import { LoadingOverlayProps } from '~/components/feedback/LoadingOverlay.vue'
import screens from '#tailwind-config/theme/screens'
import colors from '#tailwind-config/theme/colors'
import {
  DateTimeRange,
  rangeDateTimeFormats,
  ASSET_COLORS,
  Crypto,
  DEFAULT_DATE_TIME_RANGE
} from '~/data/cryptoMarket'
import { getLanguageCodeFromLocaleCode } from '~/i18n/locales'
import { LocaleCode } from '~/types/locales'
import { ErrorOverlayProps } from '~/components/feedback/ErrorOverlay.vue'

const MARGIN_X = 16

interface Props {
  data: [number, number][]
  color?: string
  secondaryData?: [number, number][]
  secondaryColor?: string
  range?: DateTimeRange
  tooltip: Pick<TooltipProps, 'name' | 'img' | 'secondaryImg' | 'size' | 'format'>
  chartHeight?: string
  hideXAxis?: boolean
  loading?: LoadingOverlayProps
  error?: ErrorOverlayProps
}

const props = withDefaults(defineProps<Props>(), {
  color: ASSET_COLORS[Crypto.Bitcoin],
  secondaryColor: ASSET_COLORS[Crypto.Ethereum],
  range: DEFAULT_DATE_TIME_RANGE,
  chartHeight: 'h-[500px] md:h-[560px]'
})

const breakpoints = useBreakpoints(screens)
const isMd = breakpoints.greater('md')
const { locale } = useI18n()
const lang = getLanguageCodeFromLocaleCode(locale.value as LocaleCode)

const chartWrapperRef = ref<HTMLElement | null>(null)
const isVisible = ref(false)
const isTooltipVisible = ref(false)
const tooltipData = ref<{
  date: number
  value: number
  secondaryValue?: number
}>()
const id = `tooltip-${new Date().getTime()}`
const hasMargins = ref(false)

const getSeries = (data: [number, number][], color: string) => ({
  name,
  type: 'spline',
  lineWidth: 2,
  marker: {
    symbol: 'circle',
    enabled: false,
    radius: 3,
    lineWidth: 3,
    lineColor: colors.purple[600],
    fillColor: '#ffffff'
  },
  states: {
    hover: {
      enabled: true,
      lineWidthPlus: 0,
      halo: { size: 0 }
    }
  },
  enabledCrosshairs: true,
  point: props.hideXAxis
    ? {}
    : {
        events: {
          mouseOver: function (this: any) {
            const chart = this.series.chart
            if (this.series.options.enabledCrosshairs) {
              chart.crosshair = chart.renderer
                .path([
                  'M',
                  chart.plotLeft + this.plotX,
                  chart.plotTop + chart.plotHeight,
                  'L',
                  chart.plotLeft + this.plotX,
                  this.plotY + chart.plotTop
                ])
                .attr({
                  'stroke-width': 1,
                  'stroke-dasharray': 4,
                  stroke: colors.neutral[300]
                })
                .add()
            }
          },
          mouseOut: function (this: any) {
            const chart = this.series.chart
            if (this.series.options.enabledCrosshairs && chart.crosshair.d) {
              chart.crosshair.destroy()
            }
          }
        }
      },
  color,
  data
})

const chartOptions = ref({
  title: { text: '' },
  xAxis: props.hideXAxis
    ? { visible: false }
    : {
        type: 'datetime',
        minPadding: 0,
        maxPadding: 0,
        tickWidth: 0,
        tickPositioner: function (this: any) {
          const positions = []
          const increment = Math.floor(
            (this.dataMax - this.dataMin) /
              ((isMd.value ? (props.range === DateTimeRange.ONE_WEEK ? 7 : 10) : 5) - 1)
          )
          let tick = Math.floor(this.dataMin)
          if (this.dataMax !== null && this.dataMin !== null) {
            for (tick; tick <= this.dataMax; tick += increment) {
              positions.push(tick)
            }
          }
          return positions
        },
        lineColor: colors.neutral[400],
        labels: {
          formatter: function (this: any) {
            return `<span class="text-body-15-regular-mobile md:text-body-18-regular-mobile text-neutral-600 ${
              this.isFirst ? 'pl-4px' : ''
            } ${this.isLast ? 'pr-4px' : ''}">${
              useDateFormat(+this.value, rangeDateTimeFormats[props.range], { locales: lang }).value
            }</span>`
          },
          useHTML: true
        }
      },
  yAxis: { visible: false },
  legend: { enabled: false },
  tooltip: {
    enabled: true,
    backgroundColor: 'transparent',
    padding: 0,
    shadow: false,
    useHTML: true,
    formatter: function (this: any) {
      tooltipData.value = {
        date: this.points[0].x,
        value: this.points[0].y,
        secondaryValue: this.points.length > 1 ? this.points[1].y : undefined
      }
      setTimeout(() => {
        isTooltipVisible.value = true
      })
      return `<div id="${id}"></div>`
    },
    shared: true
  },
  chart: {
    backgroundColor: 'transparent',
    marginLeft: 0,
    marginRight: 0,
    spacingLeft: 0,
    spacingRight: 0
  },
  accessibility: { enabled: false },
  credits: { enabled: false }
} as any)

const updateSeries = () => {
  let secondaryData = props.secondaryData
  if (secondaryData && props.data.length === secondaryData.length) {
    secondaryData = secondaryData.map(([_, value], index) => [props.data[index][0], value])
  }
  chartOptions.value.series = [
    getSeries(props.data, props.color),
    ...(secondaryData ? [getSeries(secondaryData, props.secondaryColor)] : [])
  ]
}

watch(
  () => [props.data, props.secondaryData],
  () => {
    isTooltipVisible.value = false
    updateSeries()
  }
)

const { stop } = useIntersectionObserver(
  chartWrapperRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isVisible.value = true
      setTimeout(updateSeries)
    }
  },
  { threshold: 0.75 }
)

const onResize = useDebounceFn(() => {
  setTimeout(() => {
    if (!chartWrapperRef.value) {
      return
    }
    const boundingRect = chartWrapperRef.value.getBoundingClientRect()
    const prevHasMargins = hasMargins.value
    hasMargins.value =
      boundingRect.left >= (prevHasMargins ? 0 : MARGIN_X) &&
      window.innerWidth - boundingRect.left + boundingRect.width >= (prevHasMargins ? 0 : MARGIN_X)
    chartOptions.value.chart.marginLeft = hasMargins.value ? MARGIN_X : 0
    chartOptions.value.chart.marginRight = hasMargins.value ? MARGIN_X : 0
  })
})

onMounted(() => {
  onResize()
  window.addEventListener('resize', onResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

onBeforeUnmount(() => {
  stop()
})
</script>

<style lang="postcss">
.highcharts-tooltip {
  @apply z-1;
}
</style>
