<template>
  <CryptoMarketChart
    :name="cryptoName"
    :image="cryptoImage"
    :data="cryptoData"
    :color="color ?? cryptoColor"
    :secondary-image="secondaryCryptoImage ?? ''"
    :secondary-data="secondaryCryptoData"
    :secondary-color="secondaryColor ?? secondaryCryptoColor"
    :range="range"
    :tooltip="{ ...tooltip, name: secondaryCrypto ? name : undefined }"
    :chart-height="chartHeight"
    :loading="{
      ...loading,
      show: !hideLoading && isLoading
    }"
    :error="{
      ...error,
      show: !hideError && isError,
      tryAgain: updateData
    }"
  />
</template>

<script setup lang="ts">
import { TooltipProps } from '../chart/ChartTooltip.vue'
import {
  DateTimeRange,
  rangeDays,
  DEFAULT_DATE_TIME_RANGE,
  DEFAULT_CRYPTO,
  ASSET_COLORS,
  Crypto,
  DEFAULT_SECONDARY_CRYPTO,
  MOCK_RANGE_LINE_DATA
} from '~/data/cryptoMarket'
import { LoadingOverlayProps } from '~/components/feedback/LoadingOverlay.vue'
import { ErrorOverlayProps } from '~/components/feedback/ErrorOverlay.vue'

export interface CryptoMarketPricesChartRef {
  isLoading: () => boolean
  isError: () => boolean
}

interface Props {
  name?: string
  crypto: string
  cryptoName: string
  cryptoImage: string
  secondaryCrypto?: string
  secondaryCryptoImage?: string
  color?: string
  secondaryColor?: string
  range?: DateTimeRange
  chartHeight?: string
  property?: 'prices' | 'market_caps' | 'total_volumes'
  tooltip?: TooltipProps
  loading?: LoadingOverlayProps
  hideLoading?: boolean
  error?: ErrorOverlayProps
  hideError?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  range: DEFAULT_DATE_TIME_RANGE,
  property: 'prices'
})

const { getMarketHistory } = useFeederAPI()
const currency = useCurrency()

const cryptoData = ref<[number, number][]>([])
const secondaryCryptoData = ref<[number, number][]>([])
const isCryptoDataLoading = ref(false)
const isSecondaryCryptoDataLoading = ref(false)
const isCryptoError = ref(false)
const isSecondaryCryptoError = ref(false)

const updateData = () => {
  updateCryptoData()
  updateSecondaryCryptoData()
}

const updateCryptoData = async () => {
  isCryptoError.value = false
  isCryptoDataLoading.value = true
  const { data } = await getMarketHistory({
    crypto: props.crypto,
    fiat: currency,
    days: rangeDays[props.range],
    marketCap: props.property === 'market_caps'
  })
  if (data.value) {
    cryptoData.value = data.value?.[props.property] ?? []
  } else {
    isCryptoError.value = true
    cryptoData.value = MOCK_RANGE_LINE_DATA[props.range]
  }
  isCryptoDataLoading.value = false
}

const updateSecondaryCryptoData = async () => {
  if (props.secondaryCrypto) {
    isSecondaryCryptoError.value = false
    isSecondaryCryptoDataLoading.value = true
    const { data: secondaryData } = await getMarketHistory({
      crypto: props.secondaryCrypto,
      fiat: currency,
      days: rangeDays[props.range],
      marketCap: props.property === 'market_caps'
    })
    if (secondaryData.value) {
      secondaryCryptoData.value = secondaryData.value?.[props.property] ?? []
    } else {
      isSecondaryCryptoError.value = true
      secondaryCryptoData.value = MOCK_RANGE_LINE_DATA[props.range]
    }
    isSecondaryCryptoDataLoading.value = false
  }
}

watch(() => [props.crypto, props.range], updateCryptoData, { immediate: true })
watch(() => [props.secondaryCrypto, props.range], updateSecondaryCryptoData, { immediate: true })

const cryptoColor = computed(() => {
  const color = ASSET_COLORS[props.crypto as Crypto]
  return color ?? ASSET_COLORS[DEFAULT_CRYPTO]
})
const secondaryCryptoColor = computed(() => {
  const color = ASSET_COLORS[props.secondaryCrypto as Crypto]
  return color ?? ASSET_COLORS[DEFAULT_SECONDARY_CRYPTO]
})

const isLoading = computed(() => isCryptoDataLoading.value || isSecondaryCryptoDataLoading.value)
const isError = computed(() => isCryptoError.value || isSecondaryCryptoError.value)

defineExpose({
  isLoading: () => isLoading.value,
  isError: () => isError.value
})
</script>
